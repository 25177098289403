import ExternalLink from 'components/Common/ExternalLink'
import Image from 'next/image'
import { Trans, useTranslation } from 'next-i18next'

import * as S from './styles'

export interface ContentProps {
  title: string
  children: React.ReactNode
}

const Content = ({ title, children }: ContentProps) => {
  const { t } = useTranslation(['auth'])
  return (
    <S.Container>
      <S.Logo>
        <S.RunningBoxIcon />
        <S.LogoTitle>
          <Trans components={{ bold: <S.LogoBold /> }}>{t('logo-title')}</Trans>
        </S.LogoTitle>
      </S.Logo>
      <S.Card>
        <S.Title>{title}</S.Title>
        {children}
      </S.Card>
      <S.AppStoreContainer>
        <S.AppStoreTitle>{t('driver-app')}</S.AppStoreTitle>
        <S.AppStoreImage>
          <ExternalLink href="https://apps.apple.com/us/app/cride-driver/id1516876472">
            <Image
              src="/images/app-store-brand.png"
              alt="app-store-button"
              width={68.81}
              height={22.94}
              layout="responsive"
            />
          </ExternalLink>
        </S.AppStoreImage>
      </S.AppStoreContainer>
    </S.Container>
  )
}

export default Content
