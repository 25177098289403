import RunningBoxIconSvg from 'assets/svg/running-box.svg'
import tw, { styled } from 'twin.macro'

export const Container = styled.section([
  tw`flex flex-col mt-auto w-3/4`,
  tw`md:max-w-sm`,
])

export const Logo = styled.div([
  tw`flex items-center justify-center w-full mb-3`,
])

export const RunningBoxIcon = styled(RunningBoxIconSvg)([
  tw`mr-2`,
  tw`w-16 h-16`,
  tw`md:w-24 md:h-24`,
])

export const LogoTitle = styled.h1([
  tw`text-brand-secondary-100 text-right text-xs leading-3`,
  tw`md:text-xl`,
])

export const LogoBold = styled.strong([
  tw`font-montserrat-bold text-4xl`,
  tw`md:text-6xl`,
])

export const Card = styled.div([
  tw`w-full bg-white rounded-2xl p-7 drop-shadow-sm`,
])

export const Title = styled.h1([tw`font-semibold text-sm text-center w-full`])

export const AppStoreContainer = styled.div([
  tw`flex  items-center justify-center mt-3 `,
  tw`md:flex-col-reverse`,
])

export const AppStoreTitle = styled.h1([
  tw`mr-3 text-base`,
  tw`md:mr-0 md:mt-3`,
])

export const AppStoreImage = styled.div([
  tw`w-20 h-6 cursor-pointer hover:opacity-80`,
  tw`md:w-28 md:h-9`,
])
